.cal {
    margin-bottom: 0;
    /* color: #e6e6e6; */
}

.cal th,
.cal td {
    text-align: center;
    border: 1px solid #16400a;
}

.cal-header {
    text-align: center;
    font-size: small;
}

.cal-header th {
    width: 14.285%;
    background-color: #051d0c80;
}

.cal-nav .col,
.cal-info .col {
    padding: 0;
}

.cal-nav {
    padding: 0.2rem 0.5rem;
}

.cal-nav .left {
    text-align: left;
}

.cal-nav .left .btn {
    margin: 0.125rem 1rem 0.125rem 0;
}

.cal-nav .right {
    text-align: right;
}

.cal-nav .right .btn {
    margin: 0.125rem 0 0.125rem 1rem;
}

.dateInfo .col {
    padding: 0 0.5rem;
}

.dateInfo .amh {
    padding: 1px 0.5rem;
    text-align: right;
}

.dateInfo {
    margin-top: 0.5rem;
    padding: .5rem 1.15rem;
    text-align: left;
    border-top: 1px solid #616161;
}

.dateInfo .reset {
    cursor: pointer;
    padding: 0 0.5rem;
}

.dateInfo .reset.btn {
    font-size: small !important;
}

@media screen and (max-width: 840px) {}



.day {
    cursor: pointer;
    border: 1px solid transparent; 
    text-align: center;
}

.day .lev2 {
    white-space: nowrap;
}

@media screen and (max-width: 840px) {
    /* .day .lev2 {
        font-size: small;
    } */
}

.day .lev2 span {
    display: inline-block;
    width: 50%
}

.day .d1 {
    font-size: 1.25rem;
}

.day.current {
    border: 1px solid springgreen;
    font-weight: 600;
}

.cal-nav svg {
    cursor: pointer;
}

.cal-nav .btn {
    padding: 0 0.5rem;
}

.cal td,
.cal th {
    color: #3d9e17;
}

.et {
    color: #3d9e17; 
}
.gc {
    color: #9e9d24
}

.ph {
    background-color: #0f2f0b;
    border: 1px solid #2e742f;
}

.ph-m {
    text-align: left;
    font-size: .9rem;
    padding-bottom: 1rem;
    padding: .5rem 0;
    background-color: #343a40c7;
    border-right: 1px solid #454d55;
    border-bottom: 1px solid #454d55;
    border-left: 1px solid #454d55;
}

@media screen and (max-width: 840px) {
    .ph-m {
        font-size: .9rem;
    }
}

.ph-m span {
    display: inline-block;
    min-width: 1rem;
    text-align: right;
    margin-right: 0.5rem;
}


.cal-event .event-text{
    font-size: .9em;
}
.cal-event.sel {
    border: 2px solid #2e742f;
}
.cal-event.sel .event-text {
    color: #15b417;
}
.cal-event .day-part {
    display: inline-block;
    width: 20px;
    text-align: right;
    padding-right: 4px;
}
.cal-event .event-type {
    float: right;
}